import Badge from '@/components/atoms/Badge';
import { useLinkUrl } from '@/hooks/useLinkUrl';
import { LinkType } from '@/types/generic';
import classNames from 'classnames';
import Link from 'next/link';
import SanityImage from '../atoms/SanityImage';

export type BoxCardProps = {
  link: LinkType;
  card_type: 'image' | 'information';
  className?: string;
  beforenumber?: string;
  number?: number;
  badge?: string;
  title?: string;
  text?: string;
  image?: any;
};
const BoxCard = (props: BoxCardProps) => {
  const { badge, image, link, className, card_type } = props;
  const { linkUrl } = useLinkUrl(link);

  const afterImgGradient =
    'after:absolute after:inset-0 after:bg-gradient-to-tr after:from-BW-black after:opacity-25 after:to-transparent after:content-[""] after:h-full after:w-full';
  const boxCardClasses = classNames(
    image
      ? 'text-BW-white [&>img]:hover:scale-105 ' + afterImgGradient
      : 'text-BW-black border border-BW-black hover:bg-BW-black hover:text-BW-white',
    'aspect-square h-full overflow-hidden relative rounded-3xl transition-all duration-[350ms] cursor-pointer',
    className
  );

  return (
    <Link className={boxCardClasses} href={linkUrl ?? ''}>
      {image && (
        <SanityImage
          image={image}
          width={1200}
          height={900}
          className="object-cover transition-transform duration-[350ms] absolute w-full h-full"
        />
      )}
      <div className="z-[1] card-wrapper h-full text-start relative col-span-full grid grid-cols-[inherit] gap-[inherit] justify-stretch place-content-between p-6 [&_.button]:hover:bg-BW-white [&_.button]:hover:text-BW-black [&_.button]:hover:border-BW-white">
        <div className="col-span-full flex justify-between">
          {link?.text && (
            <span className="button text-sm py-2 uppercase rounded-3xl border transition-[background-color] duration-[400ms] px-xs">
              {link.text}
            </span>
          )}

          {badge && <Badge className="place-self-end" label={badge} />}
        </div>

        {card_type === 'image' ? (
          <BoxCardWrapperImg {...props} />
        ) : (
          <BoxCardWrapperInfo {...props} />
        )}
      </div>
    </Link>
  );
};

const BoxCardWrapperImg = ({ title, text }: BoxCardProps) => (
  <div className="col-span-full flex flex-col gap-1">
    <h3 className="text-md uppercase">{title}</h3>
    <p className="max-w-[370px] max-lg:w-5/6">{text}</p>
  </div>
);
const BoxCardWrapperInfo = ({ beforenumber, number, text }: BoxCardProps) => (
  <div className="col-span-full flex flex-col gap-3">
    <span>{beforenumber}</span>
    <p className="text-huge flex flex-col">
      {number
        ?.toString()
        .split(' ')
        .map((nmbr) => (
          <span key={nmbr}>{nmbr}</span>
        ))}
    </p>
    <p>{text}</p>
  </div>
);

export default BoxCard;
