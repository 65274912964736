import classNames from 'classnames';

type BadgeType = {
  label: string;
  className?: string;
};

const Badge = ({ label, className }: BadgeType) => (
  <span
    className={classNames(
      className,
      'bg-accent-red text-BW-black rounded-full py-[8px] px-[10px] uppercase text-sm'
    )}
  >
    {label}
  </span>
);

export default Badge;
