import BoxCard, { BoxCardProps } from '@/components/BoxCard';
import ContentWrapper from '@/components/ContentWrapper';
import Button from '@/components/atoms/Button';
import { useLinkUrl } from '@/hooks/useLinkUrl';
import { LinkType } from '@/types/generic';
import { NextArray } from '@grace-studio/graceful-next/types';
import classNames from 'classnames';

export type BoxCardListProps = {
  cards: NextArray<BoxCardProps>;
  columns: 2 | 3;
  title?: string;
  number?: string;
  link?: LinkType;
};

const BoxCardList = ({
  cards,
  columns,
  title,
  number,
  link,
}: BoxCardListProps) => {
  const { linkUrl } = useLinkUrl(link);

  return (
    <ContentWrapper className="flex flex-wrap justify-between items-center gap-y-6">
      {title && (
        <h2 className="text-BW-black flex gap-1 uppercase text-lg col-span-3 lg:col-span-9">
          {title}
          {number && <span className="text-sm font-normal">{number}</span>}
        </h2>
      )}
      {link?.text && (
        <Button
          href={linkUrl}
          type="secondary"
          className="col-span-3 justify-self-end"
        >
          {link.text}
        </Button>
      )}
      <div className="grid-base gap-y-3 md:gap-y-4 lg:gap-y-6 w-full">
        {cards.map((card) => (
          <BoxCard
            className={classNames(
              columns === 3
                ? 'col-span-6 md:col-span-3 md:max-lg:first:col-span-full lg:col-span-4 md:max-lg:first:aspect-[4/3] lg:aspect-square grid-cols-4'
                : 'col-span-full md:col-span-3 lg:col-span-6 lg:aspect-[4/3]',
              'grid gap-x-[inherit]'
            )}
            key={card._key}
            {...card}
          />
        ))}
      </div>
    </ContentWrapper>
  );
};
export default BoxCardList;
